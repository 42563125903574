import React from 'react';
import { Link } from 'react-router-dom';
import CustomButton from '../../../components/custom-button/custom-button.component';
import FooterImage from '../../../components/footer-image/footer-image.component';
import Footer from '../../../components/footer/footer.component';
import SectionBreak from '../../../components/section-break/section-break.componenet';

import './irs-and-state-representation.styles.scss'
const IrsAndStateRepresentation = () => (
  <div className='irs-and-state-representation'>

    <div className='header-container'>
      <div className='header-image' style={{ backgroundImage: "linear-gradient(rgba(28, 39, 48, .2), rgba(28, 39, 48, .2)), url('../assets/picsFromJordan/maui-ocean-lagoon.jpg')" }}>
        <div className='content-container'>
          <div className='header-text-container'>
            <h1 className='header-text'>
              IRS and State Representation Services in Maui
            </h1>
            <div>
              <Link to='/contact-us'>
                <CustomButton className="inverted">Contact Us +</CustomButton>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className='section odd'>
      <p className='section-text'>
        James & Associates is a reliable CPA firm in Maui that can help clients of all sizes with their financial needs. While we are experts at day to day financial needs like tax returns and bookkeeping, we are also skilled at handling the more unique circumstances that can arise in someone’s financial life. Nothing is more unique and upsetting for most people than receiving a letter from the IRS that says they are being audited. When you receive this letter, whether you are an individual or a business owner, you will suddenly need to submit a huge amount of paperwork that can be difficult to track down. This can take away so much time that you don’t have to give and also be a process that is hard to understand for those outside the financial industry. If you have received a letter like this from the IRS and need assistance, reach out to our team today for help.
      </p>
    </div>

    <SectionBreak />

    <div className='section'>
      <div className='info-tile-container'>
        <div className='info-tile-left'>
          <div className='info-tile-text-container'>
            <p className='info-tile-title'>
              What we can do when you’re audited
            </p>
            <p className='info-tile-content'>
              While many people try to handle their audit themselves because they save money upfront, it ends up being a foolish choice. With our experts by your side, not only will the audit be completed without you needing to worry, it will also have a much more favorable outcome. Because we are trained in auditing, we know how to approach them. Give us a call as soon as you receive a letter and send us the information that we need. After that, you can go about your day to day life without worry. We will handle all the communications on your behalf and provide you with the peace of mind you deserve.
            </p>
          </div>
          <div className='info-tile-picture'
            style={{
              backgroundImage: `url('../assets/picsFromJordan/irs-and-state-representation.jpg')`
            }}>
          </div>
        </div>

      </div>
    </div>

    <FooterImage>
      Get the representation you need for your case by calling James & Associates today.
    </FooterImage>
    <Footer />

  </div>
)

export default IrsAndStateRepresentation;