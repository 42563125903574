import React from 'react';
import { Link } from 'react-router-dom';
import CustomButton from '../../../components/custom-button/custom-button.component';
import FooterImage from '../../../components/footer-image/footer-image.component';
import Footer from '../../../components/footer/footer.component';
import SectionBreak from '../../../components/section-break/section-break.componenet';

import './consulting-and-advisory.styles.scss'
const ConsultingAndAdvisory = () => (
  <div className='consulting-and-advisory'>

    <div className='header-container'>
      <div className='header-image' style={{ backgroundImage: "linear-gradient(rgba(28, 39, 48, .2), rgba(28, 39, 48, .2)), url('../assets/picsFromJordan/maui-ocean-coastline.jpg')" }}>
        <div className='content-container'>
          <div className='header-text-container'>
            <h1 className='header-text'>
              Consulting and Advisory Services in Maui
            </h1>
            <div>
              <Link to='/contact-us'>
                <CustomButton className="inverted">Contact Us +</CustomButton>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className='section odd'>
      <p className='section-text'>
        While most people think of CPA firms as tax preparation and bookkeeping destinations, there’s so much more that our financial experts can do for you. Here at James & Associates, we have been helping individuals and businesses alike with a full range of financial needs for 25 years and counting. One of the most helpful solutions for our clients is consulting, which can give people the insight they need to improve their financial situation. Learn more about how this service works below.
      </p>
    </div>

    <SectionBreak />

    <div className='section'>
      <div className='info-tile-container'>
        <div className='info-tile-left'>
          <div className='info-tile-text-container'>
            <p className='info-tile-title'>
              Individual financial planning services
            </p>
            <p className='info-tile-content'>
              If you are an individual or person with a family who is looking to optimize your finances, reach out to our team today. We can look through your tax returns, income and upcoming expenses to give you a holistic plan that can help you grow your nest egg for the future. Whether you need to save for a child to go to college, plan for retirement or anything in between, James & Associates can use our expertise to guide you to your goal.
            </p>
          </div>
          <div className='info-tile-picture'
            style={{
              backgroundImage: `url('../assets/picsFromJordan/individual-financial-consulting.jpg')`
            }}>
          </div>
        </div>

      </div>
    </div>

    <SectionBreak />

    <div className='section odd'>
      <div className='info-tile-container'>

        <div className='info-tile-right'>
          <div className='info-tile-picture'
            style={{
              backgroundImage: `url('../assets/picsFromJordan/business-consultation-services.jpg')`
            }}>
          </div>
          <div className='info-tile-text-container'>
            <p className='info-tile-title'>
              Business consultation services
            </p>
            <p className='info-tile-content'>
              James & Associates works with businesses of all different sizes and backgrounds. With this experience, we can not just perform routine services for them, but advice other businesses on the best way to run. If you are a business owner looking to maximize profits and run more efficiently, reach out to our team today. We will be happy to look through your books and give you a course of action that will lead to success for the future.
            </p>
          </div>
        </div>

      </div>
    </div>

    <FooterImage>
      For more information about financial advisory services, reach out to our team in Hawaii today.
    </FooterImage>
    <Footer />

  </div>
)

export default ConsultingAndAdvisory;