// import logo from './logo.svg';
import { Route, Switch } from 'react-router';

import './App.scss';

import Home from './pages/home/home.component';
import Header from './components/header/header.component';
import Services from './pages/services/services.component';
import AuditAndAssurance from './pages/services/audit-and-assurance/audit-and-assurance.component';
import TaxPreperation from './pages/services/tax-preperation/tax-preperation.component';
import ConsultingAndAdvisory from './pages/services/consulting-and-advisory/consulting-and-advisory.component';
import Bookkeeping from './pages/services/bookkeeping/bookkeeping.component';
import IrsAndStateRepresentation from './pages/services/irs-and-state-representation/irs-and-state-representation.component';
import ScrollToTop from './js/scroll-to-top';
import AboutUs from './pages/about-us/about-us.component';
import ContactUs from './pages/contact-us/contact-us.component';

function App() {
  return (
    <div>
      <Header />
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/services' component={Services} />
        <Route exact path='/services/audit-and-assurance' component={AuditAndAssurance} />
        <Route exact path='/services/tax-preperation' component={TaxPreperation} />
        <Route exact path='/services/consulting-and-advisory' component={ConsultingAndAdvisory} />
        <Route exact path='/services/bookkeeping' component={Bookkeeping} />
        <Route exact path='/services/irs-and-state-representation' component={IrsAndStateRepresentation} />
        <Route exact path='/about-us' component={AboutUs} />
        <Route exact path='/contact-us' component={ContactUs} />
      </Switch>
      <ScrollToTop></ScrollToTop>
    </div>
  );
}

export default App;
