import React from 'react';

import './form-input.styles.scss';

const FormInput = ({ className, handleChange, label, forName, ...otherProps }) => (
  <div className={`${className} group`}>
    <input className='form-input' id={forName} onChange={handleChange} {...otherProps} />
    { label ? (
      <label 
        htmlFor={forName}
        className={`${otherProps.value.length ? 'shrink' : ''} form-input-label ${className}`}>
        {label}
      </label>
    ) : null }
  </div>
);

export default FormInput;