import React from 'react';
import CustomButton from '../custom-button/custom-button.component';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'

import './footer-image.styles.scss'

const FooterImage = ({ children }) => (
  <div className='footer-image' >
    <div className='footer-image-container' style={{ backgroundImage: " url('../assets/picsFromJordan/maui-island-valley.jpg')" }}>
      <div className='message-box'>
        <div className='message-icon'>
          <a href='tel:8082442030'><FontAwesomeIcon icon={faPhoneAlt} className='phone-call-icon grey' size='7x' alt='Call us now image' /></a>
        </div>
        <div className='message-body'>
          <p>
            {children
              ? <p>{children}</p>
              : 'Contact us for your free tax consultation today!'
            }
          </p>
          <a href='tel: (808) 244-2030' className='clickable'>(808) 244-2030</a>
        </div>
        <div className='button-container'>
          <Link to='/contact-us'>
            <CustomButton className='footer-image-color-style'>Contact Us +</CustomButton>
          </Link>
        </div>
      </div>
    </div>
  </div>
)

export default FooterImage;