import React from 'react';
import { Link } from 'react-router-dom';

import './footer.styles.scss'

import logo from '../../assets/logos/james-and-associates-full-stacked.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from "@fortawesome/free-brands-svg-icons"
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons'


const Footer = () => (
  <div className='footer'>
    <div className='contact-info-container'>
      <Link className='logo-container' to='/'>
        <picture>
          <img className="logo" srcSet={logo} alt="Logo" />
        </picture>
      </Link>

      <div className='contact-info'>
        <div className='container'>
          <a href='https://www.google.com/maps/place/James+%26+Associates/@20.887491,-156.5061947,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xad3107a158a31087!8m2!3d20.887491!4d-156.504006'
            rel='noreferrer'
            target='_blank'>
            <div className='contact-icon' style={{
              backgroundImage: `url(https://lirp.cdn-website.com/a6ee6966/dms3rep/multi/opt/1-1920w.png)`
            }} />
          </a>
          <div className='contact-text'>
            <p>2158 Main St., #109</p>
            <p>Wailuku, HI 96793</p>

          </div>
        </div>
        <div className='container'>
          <a href='tel: (808) 244-2030' className='clickable'>
            <div className='contact-icon' style={{
              backgroundImage: `url(https://lirp.cdn-website.com/a6ee6966/dms3rep/multi/opt/2-1920w.png)`
            }} />
          </a>
          <div className='contact-text'>
            <a href='tel: (808) 244-2030' className='clickable'>(808) 244-2030</a>
            {/* <a href='tel: (808) 244-2030' className='clickable'><p>(808) 667-9512</p></a> */}
          </div>
        </div>
        <div className='container'>
          <a href='mailto: admin@cpamaui.com' className='clickable'>
            <div className='contact-icon' style={{
              backgroundImage: `url(https://lirp.cdn-website.com/a6ee6966/dms3rep/multi/opt/3-1920w.png)`
            }} />
          </a>
          <div className='contact-text'>
            admin@cpamaui.com
          </div>
        </div>
      </div>

      <div className='social-info'>
        <a href='https://www.facebook.com/cpamaui/'
          rel='noreferrer'
          target='_blank'
          className='logo-container'>
          <FontAwesomeIcon icon={faFacebook} size='2x' className='fa-icon fb' alt='Facebook link icon' />
        </a>

        <a href='https://www.google.com/maps/place/James+%26+Associates/@20.887491,-156.5061947,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xad3107a158a31087!8m2!3d20.887491!4d-156.504006'
          rel='noreferrer'
          target='_blank'
          className='logo-container'>
          <FontAwesomeIcon icon={faGlobeAmericas} size='2x' className='fa-icon location' alt='Google maps link icon' />
        </a>

        {/* <a href='mailto: admin@cpamaui.com'
          rel='noreferrer'
          target='_blank'
          className='logo-container'>
          <FontAwesomeIcon icon={faGlobeAmericas} size='2x' className='fa-icon location'/>
        </a> */}
      </div>

    </div>
    <div className='copyright'>© 2021 CPA Maui. All Rights Reserved.</div>
  </div>
)

export default Footer;
