import React from 'react';
import { Link } from 'react-router-dom';
import CustomButton from '../../components/custom-button/custom-button.component';
import FooterImage from '../../components/footer-image/footer-image.component';
import Footer from '../../components/footer/footer.component';
import InfoCardTall from '../../components/info-card-tall/info-card-tall.component';
import InfoCardTeam from '../../components/info-card-team/info-card-team.component';
import SectionBreak from '../../components/section-break/section-break.componenet';

import './about-us.styles.scss'
const AboutUs = () => (
  <div className='about-us'>

    <div className='header-container'>
      <div className='header-image' style={{ backgroundImage: "linear-gradient(rgba(28, 39, 48, .2), rgba(28, 39, 48, .2)), url('./assets/picsFromJordan/maui-resort-ocean-view.jpg')" }}>
        <div className='content-container'>
          <div className='header-text-container'>
            <h1 className='header-text'>
              Accounting Experts in Maui
            </h1>
            <div>
              <Link to='/contact-us'>
                <CustomButton className="inverted">Contact Us +</CustomButton>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className='section odd'>
      <p className='section-text'>
        James & Associates is a reliable CPA firm in Maui that can help clients of all sizes with their financial needs. While we are experts at day to day financial needs like tax returns and bookkeeping, we are also skilled at handling the more unique circumstances that can arise in someone’s financial life. Nothing is more unique and upsetting for most people than receiving a letter from the IRS that says they are being audited. When you receive this letter, whether you are an individual or a business owner, you will suddenly need to submit a huge amount of paperwork that can be difficult to track down. This can take away so much time that you don’t have to give and also be a process that is hard to understand for those outside the financial industry. If you have received a letter like this from the IRS and need assistance, reach out to our team today for help.
      </p>
    </div>

    <SectionBreak />

    <div className='section'>
      <div className='info-tile-container'>
        <div className='info-tile-left'>
          <div className='info-tile-text-container'>
            <p className='info-tile-title'>
              Our financial services
            </p>
            <p className='info-tile-content'>
              We provide our clients with a full range of accounting services, including:
            </p>

            <dl>
              <dt>Audit and Assurance</dt>
              <dd>Let us help you with all your audit needs. We can prepare understandable financial documents for you or your business.</dd>
              <dt>Tax Preparation</dt>
              <dd>Don’t let tax season stress you out. Reach out to our team for reliable tax prep and filing.</dd>
              <dt>Consulting and Advisory</dt>
              <dd>Need financial advice from our experts? Call us today to get guidance on your unique situation.</dd>
              <dt>Bookkeeping</dt>
              <dd>From payroll to full business accounting services, we can help your business have an efficient and organized financial setup.</dd>
              <dt>IRS and State Representation</dt>
              <dd>If you have been audited by the IRS, reach out to us so we can compile the necessary paperwork and handle it for you.</dd>
            </dl>

            <p className='info-tile-content'>
              No matter what type of audit and assurance services you need, you can count on James & Associates
            </p>
          </div>
          <div className='info-tile-picture'
            style={{
              backgroundImage: `url('./assets/picsFromJordan/our-financial-services.jpg')`
            }}>
          </div>
        </div>

      </div>
    </div>

    <SectionBreak />

    <div className='section odd' id='AccountingJobsinMaui'>
      <p className='title'>
        The J&A difference
      </p>
      <p className='subtitle'>
        While you have many options for your financial service needs, no one will do better than us! Below are just a few of the main factors that separate us from the competition.
      </p>
      <div className='info-card-tall-container'>
        <InfoCardTall
          imageUrl='https://lirp.cdn-website.com/a6ee6966/dms3rep/multi/opt/0_0004_01-1280w.png'
          // linkUrl='/services/audit-and-assurance'
          textHeader='Friendly, Personalized Service'
          textContent='An experienced professional will be assigned to your account and will work with you year after year. We understand the importance of continuity in audit, bookkeeping, and tax engagements and thus make sure you will have the same familiar person working with you every time.'
        />
        <InfoCardTall
          imageUrl='https://lirp.cdn-website.com/a6ee6966/dms3rep/multi/opt/0_0003_02-1280w.png'
          // linkUrl='/services/tax-preperation'
          textHeader='Availability'
          textContent='Our Wailuku office is open year-round—not just during tax season. We strive to be available as often as possible to answer all your questions and address every concern in a timely manner.'
        />
        <InfoCardTall
          imageUrl='https://lirp.cdn-website.com/a6ee6966/dms3rep/multi/opt/0_0002_03-1280w.png'
          // linkUrl='/services/consulting-and-advisory'
          textHeader='Experience'
          textContent='James & Associates has been in business on Maui for over 25 years. You don’t stay in business for over two decades by being anything less than completely reliable and trustworthy with other people’s finances.'
        />
        <InfoCardTall
          imageUrl='https://lirp.cdn-website.com/a6ee6966/dms3rep/multi/opt/0_0001_04-1280w.png'
          // linkUrl='/services/bookkeeping'
          textHeader='Professionalism'
          textContent='We are committed to maintaining the highest standards of quality. Our review includes at least two complete checks by professionals in our office before it is sent off.'
        />
        <InfoCardTall
          imageUrl='https://lirp.cdn-website.com/a6ee6966/dms3rep/multi/opt/0_0000_05-640w.png'
          // linkUrl='/services/irs-and-state-representation'
          textHeader='Friendly & Professional Staff'
          textContent='We work as a team to offer our clients fast, friendly, and quality service.'
        />
      </div>
    </div>

    <SectionBreak />

    <div className='section' id='OurTeam'>
      <p className='title'>
        Meet the team
      </p>
      <p className='subtitle'>
        {/* While you have many options for your financial service needs, no one will do better than us! Below are just a few of the main factors that separate us from the competition. */}
      </p>
      <div className='info-image-container'>
        <InfoCardTeam
          imageUrl='https://lirp.cdn-website.com/a6ee6966/dms3rep/multi/opt/Tax_Consultants-James-Wailuku-012-1280w.jpg'
          textHeader='J. Richard James, CPA'
          textContent='Richard has worked in the public accounting field for 19 years. He graduated from Brigham Young University with a Masters of Accounting in Taxation. He gained cutting edge experience and education in taxation and business accounting while working for global firms including PricewaterhouseCoopers and Grant Thornton. Richard has been the owner of James & Associates since 2006.'
        />
        <InfoCardTeam
          imageUrl='https://lirp.cdn-website.com/a6ee6966/dms3rep/multi/opt/Tax_Consultants-James-Wailuku-014-1280w.jpg'
          textHeader='Richard Long'
          textContent='After graduating from Bowling Green State University, Richard worked in the private sector, most recently on the accounting staff of several solid waste companies prior to moving to Maui. Richard has been a tax associate with James & Associates for over ten years. In addition to helping clients with Federal and State income taxes, he provides monthly, quarterly and year-end GET reporting, as well as general record keeping services.'
        />
        <InfoCardTeam
          imageUrl='https://lirp.cdn-website.com/a6ee6966/dms3rep/multi/opt/Tax_Consultants-James-Wailuku-016-1280w.jpg'
          textHeader='Barbara Long, CPA'
          textContent='Barbara has worked in public accounting for more than 35 years. A graduate of Bowling Green State University in Ohio, she has been a CPA since 1993. Barbara moved to Maui in 2001 and has been with James & Associates CPA’s, Inc. ever since. Barbara is a CPA specializing in taxation. In addition to preparing business and personal tax returns, she works with taxpayers to assist them with any IRS or State issues they may have.'
        />
        <InfoCardTeam
          imageUrl='https://lirp.cdn-website.com/a6ee6966/dms3rep/multi/opt/Tax_Consultants-James-Wailuku-015-1280w.jpg'
          textHeader='Milton Tamanaha'
          textContent="Milton is a graduate of the University of Hawaii with a Bachelor's of Business Administration and a major in Accounting. Milton is an accountant at James & Associates who has a total of over 30 years of experience in bookkeeping and tax return preparations."
        />
        <InfoCardTeam
          imageUrl='https://lirp.cdn-website.com/a6ee6966/dms3rep/multi/opt/Tax_Consultants-James-Wailuku-018-1280w.jpg'
          textHeader='Yumi Bruce'
          textContent='After moving to Maui from Japan, Yumi attended Maui Community College where she studied accounting and bookkeeping. She has been with James & Associates since 2006 and works primarily on audits and financial statements.'
        />
      </div>
    </div>

    <FooterImage>
      Ready to work with the experts at James & Associates? Call today!
    </FooterImage>
    <Footer />

  </div>
)

export default AboutUs;