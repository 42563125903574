import React from 'react';

import './info-card-team.styles.scss'

const InfoCardTeam = ({ imageUrl, textHeader, textContent }) => (
  <div className='info-card-team'>
    <div className='info-card-team-image'
      style={{
        backgroundImage: `url(${imageUrl})`
      }}
    />
    <p className='info-card-team-title'>{textHeader}</p>
    <p className='info-card-team-text'>{textContent}</p>
  </div>
)

export default InfoCardTeam;
