import React from 'react';
import { Link } from 'react-router-dom';
import CustomButton from '../../components/custom-button/custom-button.component';
import FooterImage from '../../components/footer-image/footer-image.component';
import Footer from '../../components/footer/footer.component';
import InfoImage from '../../components/info-image/info-image.component';
import SectionBreak from '../../components/section-break/section-break.componenet';

import './services.styles.scss'

const Services = () => (
  <div className='services'>

    <div className='header-container'>
      <div className='header-image' style={{ backgroundImage: "linear-gradient(rgba(28, 39, 48, .2), rgba(28, 39, 48, .2)), url('assets/picsFromJordan/lahaina-town-shops.jpg')" }}>
        <div className='content-container'>
          <div className='header-text-container'>
            <h1 className='header-text'>
              Accounting Services
            </h1>
            <div>
              <Link to='/contact-us'>
                <CustomButton className="inverted">Contact Us +</CustomButton>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className='section odd'>
      <p className='section-text'>
        Get every tax deduction you deserve when you work with the experts at James & Associates. We are a team of certified public accountants in Maui, HI, that have been serving the local area for over 25 years. Our team searches more than 350 tax deductions and credits, to ensure you get the maximum refund possible each and every year.
      </p>
    </div>

    <SectionBreak />

    <div className="section ">
      <p className='title'>
        A variety of services for our customers
      </p>
      <p className='subtitle'>
        {/* We are a full-service accounting firm comfortable working with a variety of clients including: */}
      </p>
      <div className='info-image-container'>
        <InfoImage
          imageUrl='./assets/picsFromJordan/maui-ocean-lagoon.jpg'
          textHeader='IRS Representation'
          textContent='Getting a notice from the IRS can put many people in a state of panic, but there’s no need to worry. With our team on your side, we will guide you through the audit process so everything goes smoothly. Visit out IRS representation page for more information.'
        />
        <InfoImage
          imageUrl='./assets/picsFromJordan/lahaina-maui-mountains.jpg'
          textHeader='Tax Preparation'
          textContent='Filing taxes involves a collection of different forms and varies depending on your status as either an individual, business, trust or more. We can file to ensure you stay in compliance and get the best outcome for yourself. Visit our tax preparation specific page for more information.'
        />
        <InfoImage
          imageUrl='./assets/picsFromJordan/maui-red-dirt-cliffside-lagoon.jpg'
          textHeader='Audit and Assurance'
          textContent='If you are a business looking for a proper audit to verify your income and financial situation, look no further. We can help you assure your business is running as it should be. Visit our audit and assurance page for more information.'
        />
        <InfoImage
          imageUrl='./assets/picsFromJordan/maui-beach.jpg'
          textHeader='Bookkeeping'
          textContent='If you are a busy business owner or just prefer to have us handle your books than an internal employee, we can review your finances and do normal tasks such as payroll, budgeting and accounts payable. Visit our bookkeeping page to see all we can do for your business.'
        />
        <InfoImage
          imageUrl='./assets/picsFromJordan/maui-ocean-coastline.jpg'
          textHeader='Consulting and Advisory'
          textContent='With our incredible knowledge of accounting comes an astute ability to guide your financial decisions. We can help you develop a plan for your financial future based on your goals. Visit our consulting and advisory page for more information about this service.'
        />
      </div>

      <p className='section-text'>
        We have been working with a variety of different clients for over 25 years now, making us one of the most adaptable and trustworthy accounting firms in the state. No matter what type of client you are, from an individual to a bustling, large-scale business, we will take the time to analyze your unique circumstances and guide you to the best possible outcome while remaining in compliance with all federal and state regulations. Below are the main types of clients we work with regularly.
      </p>

    </div>

    {/* <div className='section'>
      <p className='section-text'>
      We have been working with a variety of different clients for over 25 years now, making us one of the most adaptable and trustworthy accounting firms in the state. No matter what type of client you are, from an individual to a bustling, large-scale business, we will take the time to analyze your unique circumstances and guide you to the best possible outcome while remaining in compliance with all federal and state regulations. Below are the main types of clients we work with regularly.
      </p>
    </div> */}

    <SectionBreak />

    <div className='section odd'>
      <p className='title'>
        Client types we serve
      </p>


      <div className='info-tile-container'>
        <div className='info-tile-left'>
          <div className='info-tile-text-container'>
            <p className='info-tile-title'>
              Individuals
            </p>
            <p className='info-tile-content'>
              No matter how much money you make each year, tax preparation can get complicated based on your unique situation, from your job to your investments and everything in between. We can help individuals prepare and file all federal and state tax returns as well as IRS representation and so much more. Visit our Individual specific page here to find out more.
            </p>
          </div>
          <div className='info-tile-picture'
            style={{
              backgroundImage: `url(../assets/picsFromJordan/maui-individual-family.jpg)`
            }}>
          </div>
        </div>

        <div className='info-tile-right'>
          <div className='info-tile-picture'
            style={{
              backgroundImage: `url(../assets/picsFromJordan/maui-shave-ice-business.jpg)`
            }}>
          </div>
          <div className='info-tile-text-container'>
            <p className='info-tile-title'>
              Businesses
            </p>
            <p className='info-tile-content'>
              From offering assistance with tax returns to helping you obtain a license to start a brand-new business, our team is dedicated to helping the thriving economy of Hawaiian businesses. Visit out business specific page to find out more.
            </p>
          </div>
        </div>

        <div className='info-tile-left'>
          <div className='info-tile-text-container'>
            <p className='info-tile-title'>
              Non-Profit Organizations
            </p>
            <p className='info-tile-content'>
              Non-profit organizations face unique challenges on both the federal and state level and thus need knowledgeable people in their corner. Our team is well suited to help non-profits with their financial statements, tax preparation and more. Visit our non-profit specific page for more information.
            </p>
          </div>
          <div className='info-tile-picture'
            style={{
              backgroundImage: `url(../assets/picsFromJordan/non-profit-organizations.jpg)`
            }}>
          </div>
        </div>

        <div className='info-tile-right'>
          <div className='info-tile-picture'
            style={{
              backgroundImage: `url(../assets/picsFromJordan/wailuku-neighborhood.jpg)`
            }}>
          </div>
          <div className='info-tile-text-container'>
            <p className='info-tile-title'>
              Homeowner Associations
            </p>
            Condominium and timeshare associations here in Hawaii are subject to strict state laws. Hawaii AOAOs with more than 20 units total are required by law to have a public accountant annually audit their financial accounts and are also subject to at least one unannounced cash verification per year. We can help with all these laws and more.
            <p className='info-tile-content'>
            </p>
          </div>
        </div>

        <div className='info-tile-left'>
          <div className='info-tile-text-container'>
            <p className='info-tile-title'>
              Trusts and Estates
            </p>
            <p className='info-tile-content'>
              From filing mandatory estate tax returns to fiduciary accounting services, our team can help you handle the burdensome task of trust and estate finances. Visit our trust and estates specific page for more information.
            </p>
            <p className='info-tile-content'>
              No matter what your unique client needs, we have seen it all before and can help you handle things!
            </p>
          </div>
          <div className='info-tile-picture'
            style={{
              backgroundImage: `url(../assets/picsFromJordan/maui-estate.jpg)`
            }}>
          </div>
        </div>

      </div>

    </div>


    <FooterImage>
      No matter what type of service you need, our dedicated, experienced team is here to help! Call today!
    </FooterImage>
    <Footer />

  </div>
)

export default Services;