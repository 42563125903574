import React from 'react';
import { Link } from 'react-router-dom';
import CustomButton from '../custom-button/custom-button.component';

import './info-card-tall.styles.scss'

const InfoCardTall = ({ imageUrl, linkUrl, textHeader, textContent, button }) => (
  <Link to={linkUrl ? `${linkUrl}` : '#'}>
    <div className='info-card-tall'>
      <div className='icon-container'>
        <img className='icon' src={imageUrl} alt={textHeader} />
      </div>
      <p className='text-header'>
        {textHeader}
      </p>
      <p className='text-content'>
        {textContent}
      </p>
      {button && 
        <div className='button-bottom'>
          <CustomButton>Read More +</CustomButton>
        </div>
      }
    </div>
  </Link>
)

export default InfoCardTall;
