import React from 'react';
import { Link } from 'react-router-dom';
import CustomButton from '../../../components/custom-button/custom-button.component';
import FooterImage from '../../../components/footer-image/footer-image.component';
import Footer from '../../../components/footer/footer.component';
import SectionBreak from '../../../components/section-break/section-break.componenet';

import './bookkeeping.styles.scss'
const Bookkeeping = () => (
  <div className='bookkeeping'>

    <div className='header-container'>
      <div className='header-image' style={{ backgroundImage: "linear-gradient(rgba(28, 39, 48, .2), rgba(28, 39, 48, .2)), url('../assets/picsFromJordan/maui-beach.jpg')" }}>
        <div className='content-container'>
          <div className='header-text-container'>
            <h1 className='header-text'>
              Bookkeeping Services in Maui
            </h1>
            <div>
              <Link to='/contact-us'>
                <CustomButton className="inverted">Contact Us +</CustomButton>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className='section odd'>
      <p className='section-text'>
        James & Associates is a leading CPA location in Hawaii that has been helping clients big and small for over 25 years. Our team of financial experts knows how to address a full range of financial concerns that can make our clients’ lives easier. One of the most commonly requested services we offer is bookkeeping; a broad term that covers many different essential services for businesses. Learn more about these services and how they can help your business below.
      </p>
    </div>

    <SectionBreak />

    <div className='section'>
      <div className='info-tile-container'>
        <div className='info-tile-left'>
          <div className='info-tile-text-container'>
            <p className='info-tile-title'>
              What does bookkeeping include?
            </p>
            <p className='info-tile-content'>
              When you hire us to handle the bookkeeping for your company, you can pick and choose the services that matter most to you. If you already have a rich human resources department, we can just pick up some of the services that they are unable to complete. For other companies, we do all the financial bookkeeping work completely so they don’t need to hire someone in house! Here are just a few of the services you can enlist us to help with:
            </p>

            <ul>
              <li>Accounting (Daily, weekly, monthly or annually as needed)</li>
              <li>Payroll Services</li>
              <li>Tax Preparation</li>
              <li>Cloud Accounting</li>
              <li>And more!</li>
            </ul>

            <p className='info-tile-content'>
              Whether you are looking to take all the bookkeeping off your business’s plate or simply need some minor help with some of the bigger projects, you can count on James & Associates.
            </p>
          </div>
          <div className='info-tile-picture'
            style={{
              backgroundImage: `url('../assets/picsFromJordan/bookkeeping-accounting.jpg')`
            }}>
          </div>
        </div>

      </div>
    </div>

    <FooterImage>
      Need bookkeeping services in Hawaii? Call us today.
    </FooterImage>
    <Footer />

  </div>
)

export default Bookkeeping;