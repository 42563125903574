import React from 'react';
import CustomButton from '../../components/custom-button/custom-button.component';
import FormInput from '../../components/form-input/form-input.component';

import './sign-in.styles.scss';

class SignIn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      phone: '',
      message: ''
    }
  }

  validate = () => {
    return true
  }

  handleSubmit = event => {
    event.preventDefault();
    const { name, email, phone, message } = this.state
    const data = { name, email, phone, message }

    if (this.validate()) {
      fetch('/api/email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(data => {
          console.log('Success:', data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
  
      this.setState({ name: '', email: '', phone: '', message: '' })
    } else {
      console.log('validation error')
    }

  }

  handleChange = event => {
    const { value, name } = event.target;
    console.log('test')

    this.setState({ [name]: value })
  }

  render() {
    const { disclaimer } = this.props;

    return (
      <div className='sign-in disable-highlight'>
        <h2 className='title'>Get in Touch</h2>
        <span className='subtitle'>*Required</span>

        <form onSubmit={this.handleSubmit}>

          <FormInput
            name='name'
            type='name'
            label='*Name:'
            forName='name'
            value={this.state.name}
            handleChange={this.handleChange}
            required
          />


          <FormInput
            name='email'
            type='email'
            label='*Email:'
            forName='email'
            value={this.state.email}
            handleChange={this.handleChange}
            required
          />

          <FormInput
            name='phone'
            type='tel'
            label='*Phone:'
            forName='phone'
            value={this.state.phone}
            handleChange={this.handleChange}
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}|[0-9]{10}"
            required
          />

          <FormInput
            name='message'
            type='text'
            label='*Message:'
            forName='message'
            value={this.state.message}
            handleChange={this.handleChange}
            required
          />

          {disclaimer &&
            <p className='disclaimer'>
              We will use the information provided in this form to contact you about your query. By submitting this form, you agree that we may collect and use your information for the reasons stated above.
            </p>
          }

          <CustomButton className='inverted' type='submit'>Submit</CustomButton>
        </form>
      </div>
    )
  }
};

export default SignIn;
