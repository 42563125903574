import React from 'react';
import { Link } from 'react-router-dom';
import CustomButton from '../../../components/custom-button/custom-button.component';
import FooterImage from '../../../components/footer-image/footer-image.component';
import Footer from '../../../components/footer/footer.component';
import SectionBreak from '../../../components/section-break/section-break.componenet';

import './audit-and-assurance.styles.scss'
const AuditAndAssurance = () => (
  <div className='audit-and-assurance'>

    <div className='header-container'>
      <div className='header-image' style={{ backgroundImage: "linear-gradient(rgba(28, 39, 48, .2), rgba(28, 39, 48, .2)), url('../assets/picsFromJordan/maui-red-dirt-cliffside-lagoon.jpg')" }}>
        <div className='content-container'>
          <div className='header-text-container'>
            <h1 className='header-text'>
              Audit and Assurance Services in Maui
            </h1>
            <div>
              <Link to='/contact-us'>
                <CustomButton className="inverted">Contact Us +</CustomButton>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className='section odd'>
      <p className='section-text'>
        For over two decades, James & Associates has been a leading accounting firm in Maui, HI. Our team of experts knows how to handle a full range of financial services for individuals, families and small businesses alike. Learn more about one of our most popular service offerings, auditing, in more detail below.
      </p>
    </div>

    <SectionBreak />

    <div className='section'>
      <div className='info-tile-container'>
        <div className='info-tile-left'>
          <div className='info-tile-text-container'>
            <p className='info-tile-title'>
              Experienced auditing experts
            </p>
            <p className='info-tile-content'>
              Our seasoned team of financial experts knows how to handle an audit professionally. We believe there is much more to an audit than a simple financial statement, so we take a more holistic approach that gives our clients the in-depth and helpful information they need in a clear way. By talking to you openly about your situation, we can help you improve your company. Our auditing team is skilled at performing all types of audits, including:
            </p>

            <ul>
              <li>Non-profit Audits</li>
              <li>Single Audits</li>
              <li>Charter and Private School Audits and Reviews</li>
              <li>Homeowner Association Audits</li>
              <li>Employee Benefit Plan Audits</li>
              <li>Small Business Audits</li>
              <li>Government Entity Audits</li>
              <li>And more</li>
            </ul>

            <p className='info-tile-content'>
              No matter what type of audit and assurance services you need, you can count on James & Associates
            </p>
          </div>
          <div className='info-tile-picture'
            style={{
              backgroundImage: `url('../assets/picsFromJordan/audit-accounting-meeting.jpg')`
            }}>
          </div>
        </div>

      </div>
    </div>

    <SectionBreak />

    <div className='section odd'>
      <div className='info-tile-container'>

        <div className='info-tile-right'>
          <div className='info-tile-picture'
            style={{
              backgroundImage: `url('../assets/picsFromJordan/audit-financial-statements.jpg')`
            }}>
          </div>
          <div className='info-tile-text-container'>
            <p className='info-tile-title'>
              Financial statements you can understand
            </p>
            <p className='info-tile-content'>
              We know that finances are not everyone’s strong suit and that hiring an accountant is meant to take the hard work out of the job. That’s why we create audited financial statements for the companies we work for that are easy to understand and use. With our team by your side, you can easily navigate the turbulent financial waters of an audit with ease.
            </p>
          </div>
        </div>

      </div>
    </div>

    <FooterImage>
      Call our team today to get a free quote on audit services in Hawaii.
    </FooterImage>
    <Footer />

  </div>
)

export default AuditAndAssurance;