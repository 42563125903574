import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
// import { faBars } from '@fortawesome/free-solid-svg-icons'

import './header.styles.scss'

import logo from '../../assets/logos/james-and-associates-full-long.png';
import logoSmall from '../../assets/logos/james-and-associates-logo.png';
import LinkButton from '../link-button/link-button.component';

// console.log(logo); // /logo.84287d09.png


class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      menuActive: false
    }
  }

  render() {
    const { menuActive } = this.state;

    return (
      // Import result is the URL of your image
      <>
        <div className="header">
          <div className="header-container-nav">
            <Link className='logo-container' onClick={() => console.log("Clicked")} to='/'>
              <picture>
                <img className="logo header-logo-desktop" srcSet={logo} alt="Logo" />
              </picture>
              <picture>
                <img className="logo header-logo-mobile" srcSet={logoSmall} alt="Logo" />
              </picture>
            </Link>
            <div className='nav-container'>
              <Link className='nav-link' to='/'>
                Home
              </Link>
              <Link className='nav-link nav-dropdown' to='/services'>
                Services <span className="arrow arrow-bottom"></span>
                <div className='test'>
                  <Link className='nav-link' to='/services/audit-and-assurance'>
                    Audit and Assurance
                  </Link>
                  <Link className='nav-link' to='/services/tax-preperation'>
                    Tax Preparation
                  </Link>
                  <Link className='nav-link' to='/services/consulting-and-advisory'>
                    Consulting and Advisory
                  </Link>
                  <Link className='nav-link' to='/services/bookkeeping'>
                    Bookkeeping
                  </Link>
                  <Link className='nav-link' to='/services/irs-and-state-representation'>
                    IRS and State Rrepresentation
                  </Link>
                </div>
              </Link>
              <Link className='nav-link nav-dropdown' to='/about-us'>
                About Us <span className="arrow arrow-bottom"></span>
                <div className='test'>
                  <Link className='nav-link' to={{ pathname: '/about-us', hash: 'AccountingJobsinMaui' }}>
                    Accounting Jobs in Maui
                  </Link>
                  <HashLink className='nav-link' to='/about-us#OurTeam'>
                    Our Team
                  </HashLink>
                </div>
              </Link>
              <Link className='nav-link' to='/contact-us'>
                Contact Us
              </Link>
              <a href='https://cpamaui.clientportal.com/' rel="noreferrer" target='_blank' className='nav-link'>
                Client Portal
              </a>
              <LinkButton href='tel:8082442030' > <FontAwesomeIcon icon={faPhone} className='phone-icon' />(808) 244-2030</LinkButton>
            </div>

            <div className='mobile-nav-container'>
              <LinkButton href='tel:8082442030' > <FontAwesomeIcon icon={faPhone} className='phone-icon' /><p className='link-button-text'> (808) 244-2030</p></LinkButton>
              <div className={`mobile-menu disable-highlight clickable`} onClick={() => this.setState({ menuActive: !this.state.menuActive })}>
                <div className={`${menuActive ? 'change' : ''} bar1`}></div>
                <div className={`${menuActive ? 'change' : ''} bar2`}></div>
                <div className={`${menuActive ? 'change' : ''} bar3`}></div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${menuActive ? 'visable' : ''} mobile-menu-dropdown`}>
          <Link onClick={() => this.setState({ menuActive: false })} to='/'>
            <div className='nav-link-mobile'>Home</div>
          </Link>
          <Link onClick={() => this.setState({ menuActive: false })} to='/services'>
            <div className='nav-link-mobile'>Services</div>
          </Link>
          <Link onClick={() => this.setState({ menuActive: false })} to='/services/audit-and-assurance'>
            <div className='nav-link-mobile'>Audit and Assurance</div>
          </Link>
          <Link onClick={() => this.setState({ menuActive: false })} to='/services/tax-preperation'>
            <div className='nav-link-mobile'>Tax Preperation</div>
          </Link>
          <Link onClick={() => this.setState({ menuActive: false })} to='/services/consulting-and-advisory'>
            <div className='nav-link-mobile'>Consulting and Advisory</div>
          </Link>
          <Link onClick={() => this.setState({ menuActive: false })} to='/services/bookkeeping'>
            <div className='nav-link-mobile'>Bookkeeping</div>
          </Link>
          <Link onClick={() => this.setState({ menuActive: false })} to='/services/irs-and-state-representation'>
            <div className='nav-link-mobile'>IRS and State Rrepresentation</div>
          </Link>
          <Link onClick={() => this.setState({ menuActive: false })} to='/about-us'>
            <div className='nav-link-mobile'>About Us</div>
          </Link>
          <Link onClick={() => this.setState({ menuActive: false })} to='/contact-us'>
            <div className='nav-link-mobile'>Contact Us</div>
          </Link>
          <a href='https://cpamaui.clientportal.com/' rel="noreferrer" target='_blank' onClick={() => this.setState({ menuActive: false })}>
            <div className='nav-link-mobile'>Client Portal</div>
          </a>
        </div>
      </>
    )
  }
}


export default Header;